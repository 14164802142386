import {
  VIDEO_TOKEN,
  VIDEO_TOKEN_RESPONSE,
  LOG_OUT,
  LOG_OUT_RES,
  CHAT_HISTORY,
  CHAT_HISTORY_RES,
} from './video.types'

export const videoTokenApi = (params) => ({
  type: VIDEO_TOKEN,
  payload: params,
})

export const VideoTokenResponse = (token) => ({
  type: VIDEO_TOKEN_RESPONSE,
  payload: token,
})

export const logOutApi = () => ({
  type: LOG_OUT,
})

export const logOutResponse = (res) => ({
  type: LOG_OUT_RES,
  payload: res,
})

export const chatHistory = (param) => ({
  type: CHAT_HISTORY,
  payload: param,
})

export const chatHistoryResponse = (res) => ({
  type: CHAT_HISTORY_RES,
  payload: res,
})
