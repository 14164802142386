import { VIDEO_TOKEN_RESPONSE, LOG_OUT_RES,CHAT_HISTORY_RES} from './video.types'

const INITIAL_STATE = {
  tokenResponse: null,
  logoutresponse: null,
  chatHistory:null,
}

const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIDEO_TOKEN_RESPONSE: {
      return { ...state, ...{ tokenResponse: action.payload } }
    }
    case LOG_OUT_RES: {
      return { ...state, ...{ logoutresponse: action.payload } }
    }
    case CHAT_HISTORY_RES: {
      return { ...state, ...{ chatHistory: action.payload } }
    }
    default:
      return state
  }
}
export default videoReducer
