import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as UtilsAjax } from 'Utils'
import { VIDEO_TOKEN, REQUEST_CANCEL, LOG_OUT,CHAT_HISTORY } from './video.types'
import { VideoTokenResponse, logOutResponse,chatHistoryResponse } from './video.actions'
import environment from 'environments/environment.dev'
const baseUrl = environment.baseUrl;
const VideoEpic = {}

VideoEpic.videoToken = (action$) =>
  action$.pipe(
    ofType(VIDEO_TOKEN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/video-patient-api/v1/video/patient/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => VideoTokenResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

VideoEpic.logoutApi = (action$) =>
  action$.pipe(
    ofType(LOG_OUT),
    switchMap(() =>
      UtilsAjax({
        url: `${baseUrl}/video-patient-api/v1/video/patient/logout`,
        method: 'POST',
      }).pipe(
        map(
          (response) => logOutResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

  VideoEpic.chatHistoryApi = (action$) =>
  action$.pipe(
    ofType(CHAT_HISTORY),
    switchMap((action) =>
    ajax({
        headers: { 
          'Content-Type': 'application/json',
          'Authorization':localStorage.getItem('url')
         },
        url: `${baseUrl}/messaging-api/v1/chat/patient/messages/list?appointmentId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => chatHistoryResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default VideoEpic
