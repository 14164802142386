import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
const VideoChat = React.lazy(() => import('./VideoComponents/VideoChat'))
// const Login = React.lazy(() => import('./Components/Login'))
const App = () => {
  return (
    <div className="app">
      <main>
        <BrowserRouter>
        <React.Suspense fallback={'loading'}>
          <Switch>
            <Route path="/" component={VideoChat} />
            {/* <Route path="/video" component={VideoChat} />
             <Redirect from="/" to="/chat" /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      </main>
  
    </div>
  );
};

export default App;

